import { ValidatorFn, Validators } from '@angular/forms';

//Phone validator for mobile phone number that is entered through inputs
//Validates Finnish mobile phone numbers
//eg. +358 40 123 1234

export const finnishMobilePhoneValidator: ValidatorFn[] = [
	Validators.required,
	Validators.pattern(/^((\(?\+358\)?)?)(\s?\d{2}\s?\d{3}\s?\d{4})$/),
];
