<div class="flex flex-col justify-between h-full">
	<div class="flex flex-col w-full pt-6">
		<div class="flex flex-col w-full">
			<h1 class="mb-2" translate="inline-events.title"></h1>
			<div *ngIf="!hideTransactions" class="flex-1 w-full">
				<div *ngIf="transactions?.length > 0" class="flex-1 w-full">
					<div *ngIf="!!(nextPayment$ | async)" class="flex items-center justify-between w-full border-b py-common border-light-grey">
						<div class="flex flex-col">
							<p class="text-dark-grey grow-0">
								<span translate="inline-events.type.next-payment"></span>
							</p>
							<p class="text-sm text-dark-grey">{{ (nextPayment$ | async)?.estimated_date | localDate }}</p>
						</div>
						<p class="shrink-0 text-dark-grey">{{ (nextPayment$ | async)?.amount | money:0 }}</p>
					</div>
					<div *ngFor="let transaction of transactions | slice:0:5; let i = index" class="flex items-center justify-between w-full border-b py-common border-light-grey">
						<div class="flex flex-col">
							<p *ngIf="transaction.transaction_type ==='payment'" class="text-almost-black grow-0">
								<span *ngIf="transaction.payment_reason === 'Planned'" translate="inline-events.type.ag"></span>
								<span *ngIf="transaction.payment_reason === 'Manual' || transaction.payment_reason === 'ExtraAmortizationOnly'" translate="inline-events.type.extra-payment"></span>
								<span *ngIf="transaction.feed_type === 'monitor-imported'" translate="transactions.type.monitor-imported"></span>
							</p>
							<p
								*ngIf="transaction.transaction_type ==='withdrawal'"
								class="grow-0 text-title"
								[innerHtml]="'inline-events.type.new-loan' | translate: { amount: abs(transaction.principal_amount.amount) | asMoney:transaction.principal_amount.currency:0 }"></p>
							<p class="text-sm">{{ transaction.transaction_date | localDate }}</p>
						</div>
						<p *ngIf="transaction.transaction_type ==='payment'" class="text-almost-black shrink-0">{{ transaction.total_amount | money:0 }}</p>
						<p *ngIf="transaction.transaction_type ==='withdrawal'" class="shrink-0 text-title">{{ transaction.principal_amount | money:0 }}</p>
					</div>
				</div>
				<div *ngIf="transactions?.length === 0" class="flex flex-col items-start flex-1 max-w-xs" [ngClass]="{'p-common': !partner, 'py-common md:p-common': partner}">
					<p translate="inline-events.empty.pre"></p>
				</div>
			</div>

			<div *ngIf="hideTransactions" class="flex flex-col items-start flex-1 max-w-xs" [ngClass]="{'p-common': !partner, 'py-common md:p-common': partner}">
				<p translate="inline-events.hide.pre"></p>
			</div>
		</div>
		<div *ngIf="!transactions" class="flex items-center flex-1 w-full lg:justify-center">
			<app-loader></app-loader>
		</div>
	</div>
	<div class="py-common w-fit" *ngIf="transactions?.length > 0">
		<app-button [disabled]="hideTransactions" [iconId]="'plus-icon'" [type]="'small'" (action)="clickedMore.emit()">{{ 'inline-events.more' | translate }}</app-button>
	</div>
</div>
