import { DayOfWeek } from 'app/api';

export const lessThanTwoWeeksAgo = (date): boolean => {
	const todayDate = new Date();
	const requestDate = new Date(date);
	const daysBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60 * 24);
	return daysBetween <= 14;
};

export const lessThanOneHourAgo = (date): boolean => {
	const todayDate = new Date();
	const requestDate = new Date(date);
	const hoursBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60);
	return hoursBetween <= 1;
};

export const lessThanOneMonthAgo = (date): boolean => {
	const todayDate = new Date();
	const requestDate = new Date(date);
	const daysBetween = (todayDate.getTime() - requestDate.getTime()) / (1000 * 60 * 60 * 24);
	return daysBetween <= 30;
};

export function dayOfWeekMapping(index: number): DayOfWeek {
	const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
	return days[index] as DayOfWeek;
}
