<div class="flex justify-center w-full">
	<div [ngClass]="widthClass" class="flex flex-col flex-1 px-6 py-6 w-full rounded-2xl sm:px-10 bg-system-green">
		<div class="flex relative gap-2 w-full">
			<div class="text-center sm:text-left">
				<p class="text-almost-white" translate="approved-payout.subtitle"></p>
				<h1 class="font-medium text-white" translate="approved-payout.title"></h1>
				<p class="pt-2 text-almost-white" translate="approved-payout.pre"></p>
			</div>
		</div>
	</div>
</div>
