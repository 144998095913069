import { Injectable } from '@angular/core';
import { b64DecodeUnicode } from 'app/helpers/encoding.helper';
import { User } from 'app/models/user';
import { SentryService, SessionCacheService, UserService } from 'app/services';
import { LocalStorageService } from 'app/services/local-storage.service';
import * as dayjs from 'dayjs';
import { environment } from 'environments/environment';

export class AuthenticationStatus {
	public isAuthenticated: boolean;
	public hasToken: boolean;
	public tokenExpired: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private sessionCache: SessionCacheService,
		private localStorage: LocalStorageService,
		private userService: UserService,
		private sentryService: SentryService
	) {}

	private token: string;

	public setToken(token: string): void {
		this.sentryService.addBreadcrumb('Token set', 'auth-service');
		this.token = token;
		this.sessionCache.set('token', token);
		this.localStorage.set('token', token);
	}

	public removeToken(): void {
		this.sentryService.addBreadcrumb('Token removed', 'auth-service');
		this.token = null;
		this.sessionCache.remove('token');
		this.localStorage.remove('token');
	}

	public getToken(): string {
		if (!this.token) {
			this.sentryService.addBreadcrumb(
				'Token not found in service, attempting to restore from storage',
				'auth-service'
			);
			if (this.sessionCache.get('token')) {
				this.sentryService.addBreadcrumb('Token restored from session cache', 'auth-service');
				this.token = this.sessionCache.get('token');
			} else if (this.localStorage.get('token')) {
				this.sentryService.addBreadcrumb('Token restored from local storage', 'auth-service');
				this.token = this.localStorage.get('token');
			}
		}
		return this.token;
	}

	public restoreSessionTokenFromStorage() {
		const existingToken = this.getToken();
		if (existingToken == null || existingToken === '') {
			const token = this.localStorage.get('token');
			if (token != null && token !== 'undefined') this.sessionCache.set('token', token);
		}
	}

	public isAuthenticated(): AuthenticationStatus {
		const token = this.getToken();

		if (!token) {
			return {
				isAuthenticated: false,
				hasToken: false,
				tokenExpired: false,
			};
		}

		const splits = token.split('.');

		if (splits.length < 2) {
			return {
				isAuthenticated: false,
				hasToken: true,
				tokenExpired: false,
			};
		}

		const data = JSON.parse(b64DecodeUnicode(splits[1]));
		const expiryDate = dayjs.unix(data.exp);

		if (dayjs(expiryDate).isBefore(new Date())) {
			this.sentryService.addBreadcrumb('Token expired', 'auth-service', {
				expiryDate: dayjs(expiryDate).toString(),
				currentDate: dayjs(new Date()).toString(),
			});
			return {
				isAuthenticated: false,
				hasToken: true,
				tokenExpired: true,
			};
		}

		return {
			isAuthenticated: true,
			hasToken: true,
			tokenExpired: false,
		};
	}

	private getPrivilegesFromToken() {
		const token = this.getToken();

		if (!token) {
			return null;
		}

		const splits = token.split('.');

		if (splits.length < 2) {
			return null;
		}

		const data = JSON.parse(b64DecodeUnicode(splits[1]));

		if (!data) {
			return null;
		}

		if (!data.privilegeCollection) {
			return null;
		}

		if (!data.privilegeCollection.privileges) {
			return null;
		}

		return data.privilegeCollection.privileges;
	}

	public isBackOfficeUser(skipEnvironmentCheck = false): boolean {
		if (!skipEnvironmentCheck && !environment.production) return false;

		const privileges = this.getPrivilegesFromToken();

		if (privileges === null) return false;

		for (let i = 0; i < privileges.length; i++) {
			const privilege = privileges[i];

			for (let y = 0; y < privilege.scopes.length; y++) {
				const scope = privilege.scopes[y];

				if (scope === 'back-office:user') {
					return true;
				}
			}
		}

		return false;
	}

	public isOwnerUser(orgId): boolean {
		const privileges = this.getPrivilegesFromToken();

		if (privileges === null) return false;

		for (let i = 0; i < privileges.length; i++) {
			const privilege = privileges[i];

			for (let y = 0; y < privilege.resources.length; y++) {
				const resource = privilege.resources[y];

				if (resource === `organization:${orgId}`) {
					for (let z = 0; z < privilege.scopes.length; z++) {
						const scope = privilege.scopes[z];

						if (scope === 'owner') {
							return true;
						}
					}
				}
			}
		}

		return false;
	}

	public setUserFromToken(token: string) {
		const splits = token.split('.');
		const data = JSON.parse(b64DecodeUnicode(splits[1]));

		const user = new User();

		user.email = data.email;
		user.first_name = data.firstName;
		user.last_name = data.lastName;
		user.id = data.sub;

		this.userService.setUser(user);
	}
}
