export const LENDERS_LIST = [
	'OPR',
	'CAPCITO',
	'QRED',
	'CAPITALBOX',
	'CREDIGO',
	'CORPIA',
	'AROSKAPITAL',
	'SVEA',
	'MARGINALEN',
	'DBT',
];

export const DEFAULT_MIN_LOAN_AMOUNTS = {
	EUR: 500,
	DKK: 5_000,
	SEK: 5_000,
};

//mapper for partners that have different id and key
//id is sent from the backend as line of credit - [partnerId]
//key is used in the FE for themes and client settings
export const PARTNER_ID_TO_KEY_MAPPER = {
	swedbankpay: 'swedbank-pay',
	'visma-lending': 'visma',
	'visma-invoice-financing': 'visma-invoice',
};

export const DEFAULT_APP_LANGUAGE = 'sv';
export const DEFAULT_PARTNER_LANGUAGE = 'en';

export const PLACEHOLDER_SWEDISH_PHONE_NUMBER = '701 23 45 67';

//Unleash feature flags
export const FORCE_SKIP_SCRAPING_FLAG = 'SkipScraping';
export const MAINTENANCE_MODE = 'MaintenanceMode';
export const NEW_FLEX_LOAN = 'NewFlexLoan';
export const ENABLE_FLEX_LOAN = 'EnableFlexLoan';
export const CARD_BIN_CHECKING = 'CardBinChecking';
export const WL_KILL_SWITCH = 'WhiteLabelApplicationKillSwitch';
export const GENERAL_KILL_SWITCH = 'GeneralKillSwitch';
export const PARTNER_PORTAL_KILL_SWITCH = 'PartnerPortalKillSwitch';
export const ONBOARDING_KILL_SWITCH = 'OnboardingFlowKillSwitch';
export const CONTRACT_KILL_SWITCH = 'ContractKillSwitch';
export const DISABLED_FINAL_PAYMEMNT_FLAG = 'DisableFinalPaymentLs';
export const DISABLED_CHANGE_RATE_FLAG = 'DisableChangeRateLs';
export const DISABLED_PAUSE_LOAN_FLAG = 'DisablePauseLoanLs';
export const DISABLED_AMORTIZATION_FLAG = 'DisableAmortizationLs';

export const CUSTOMIZED_LENDING_LIMIT = 'CustomizedLendingLimit';

export const MEDIUM_BREAKPOINT = 640;
