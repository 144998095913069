export function b64DecodeUnicode(str: string): string {
	// Going backwards: from bytestream, to percent-encoding, to original string.
	str = str.replace(/\s+/g, '').replace(/-/g, '+').replace(/_/g, '/');
	return decodeURIComponent(
		atob(str)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
}
