import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
	CreditProductHttpService,
	OnboardingSignatoryDto,
	OrganizationsHttpService,
	SignatoryPersonDto,
	SignatoryPersonRequest,
} from 'app/api';
import { PLACEHOLDER_SWEDISH_PHONE_NUMBER } from 'app/app.contants';
import {
	danishMobilePhoneValidator,
	emailAsyncValidator,
	germanMobilePhoneValidator,
	signatoryAsyncValidator,
	swedishMobilePhoneValidator,
} from 'app/shared/validators';
import { finnishMobilePhoneValidator } from 'app/shared/validators/finnishMobilePhoneValidator';
import {
	DANISH_PHONE_PREFIX,
	FINNISH_PHONE_PREFIX,
	GERMAN_PHONE_PREFIX,
	SWEDISH_PHONE_PREFIX,
} from 'app/white-label-client/white-label-client.constants';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-signatories',
	templateUrl: './signatories.component.html',
	styleUrls: ['./signatories.component.scss'],
})
export class SignatoriesComponent implements OnInit, OnDestroy {
	@Input() selectableSignatories: SignatoryPersonDto[] | OnboardingSignatoryDto[];
	@Input() selectedSignatories: SignatoryPersonRequest[] | OnboardingSignatoryDto[];
	@Input() phonePrefix: string = SWEDISH_PHONE_PREFIX;
	@Input() phonePlaceholder: string = PLACEHOLDER_SWEDISH_PHONE_NUMBER;
	@Input() organizationId: string;
	signatoriesForm: FormGroup;
	validationAttempt = false;
	protected readonly destroy$ = new Subject();

	constructor(
		private formBuilder: FormBuilder,
		private creditProductService: CreditProductHttpService,
		private organizationHttpService: OrganizationsHttpService
	) {}

	ngOnInit() {
		this.initializeForm(this.selectableSignatories, this.selectedSignatories);
	}

	initializeForm(selectableSignatories, selectedSignatories) {
		this.signatoriesForm = this.formBuilder.group({
			signatories: this.formBuilder.array(
				selectableSignatories.map(b => {
					const initialSignatory = (selectedSignatories as any)?.find(
						signatory =>
							signatory.board_member_id === b.id ||
							signatory.board_member_id === b.board_member_id ||
							signatory.id === b.board_member_id ||
							signatory.id === b.id
					);
					return this.formBuilder.group(
						{
							id: [b.id || b.board_member_id || initialSignatory?.board_member_id || initialSignatory?.id],
							name: [b.name || initialSignatory?.name],
							first_name: [b.first_name || initialSignatory?.first_name],
							last_name: [b.last_name || initialSignatory?.last_name],
							ssn: [b.ssn || initialSignatory?.ssn],
							email: [
								b.email || initialSignatory?.email || null,
								{
									validators: [Validators.required],
									asyncValidators: emailAsyncValidator(this.organizationHttpService),
									updateOn: 'blur',
								},
							],
							phone: [
								b.phone || initialSignatory?.phone || null,
								{
									validators: this.validator,
									updateOn: 'blur',
								},
							],
							included: [!!initialSignatory],
						},
						{
							validators: [this.signatoryValidator],
						}
					);
				}),
				{
					validators: [this.atLeastOne(Validators.requiredTrue)],
					asyncValidators: [signatoryAsyncValidator(this.creditProductService, this.organizationId)],
				}
			),
		});
	}

	get validator() {
		if (this.phonePrefix === DANISH_PHONE_PREFIX) {
			return danishMobilePhoneValidator;
		} else if (this.phonePrefix === GERMAN_PHONE_PREFIX) {
			return germanMobilePhoneValidator;
		} else if (this.phonePrefix === FINNISH_PHONE_PREFIX) {
			return finnishMobilePhoneValidator;
		} else {
			return swedishMobilePhoneValidator;
		}
	}

	get signatoriesCtrl(): UntypedFormArray {
		return this.signatoriesForm.get('signatories') as UntypedFormArray;
	}

	validateForm(): boolean {
		this.validationAttempt = true;
		this.signatoriesForm.markAllAsTouched();
		return this.signatoriesForm.valid;
	}

	returnOnboardingSignatories(): OnboardingSignatoryDto[] {
		return this.signatoriesCtrl.value.map(s => {
			return {
				board_member_id: s.id,
				first_name: s.first_name,
				last_name: s.last_name,
				email: s.email,
				phone: s.phone,
				is_selected_by_customer: s.included,
			};
		});
	}

	returnSignatories(): SignatoryPersonRequest[] {
		return this.signatoriesCtrl.value
			.filter(s => s.included)
			.map(s => {
				return {
					board_member_id: s.id,
					name: s.name,
					email: s.email,
					phone: s.phone,
				};
			});
	}

	signatoryValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
		const included = control.get('included');
		const email = control.get('email');
		const phone = control.get('phone');
		if (included.value) {
			if (!email.value) email.setErrors({ required: true });
			if (!phone.value) phone.setErrors({ required: true });
		} else {
			email.setErrors(null);
			phone.setErrors(null);
		}
		return null;
	};

	atLeastOne =
		(validator: ValidatorFn) =>
		(group: FormGroup): ValidationErrors | null => {
			const hasAtLeastOne =
				group &&
				group.controls &&
				Object.keys(group.controls).some(k => !validator((group.controls[k] as FormGroup).controls.included));

			return hasAtLeastOne ? null : { atLeastOne: true };
		};

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
