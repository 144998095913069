<button
	#buttonId
	type="button"
	[disabled]="disabled || loading"
	class="w-full text-base transition-colors duration-200 min-w-button focus:outline-none"
	[class.opacity-25]="disabled || loading"
	[class.cursor-not-allowed]="disabled || loading"
	[class.pointer-events-none]="disabled || loading"
	[class.button-loading]="loading"
	[ngClass]="{
		'rounded-button': type === 'primary' || type === 'outline' || type ==='small',
		'p-button': type === 'primary' || type === 'outline',
		'border-2': type === 'outline' || type === 'secondary',
		'bg-button-color text-button-text  hover:bg-button-color-hover hover:text-button-text-hover': type === 'primary',
		'text-button-text-secondary rounded-button-secondary bg-button-color-secondary border-button-border-secondary-outlined p-button-secondary-outlined hover:text-button-text-secondary-hover hover:border-button-border-secondary-hover': type === 'secondary',
		'bg-transparent text-dark border-dark': type === 'outline' && outlineVersion === 'light',
		'bg-transparent border-white': type === 'outline' && outlineVersion === 'dark',
		'px-3 h-9 border shadow-inner-button': type === 'small',
		'text-black bg-white-very-transparent border-black-6 hover:border-black active:bg-black-6': type === 'small' && darkMode === false,
		'bg-black-6 border-white-very-transparent hover:border-white active:bg-white-very-transparent': type === 'small' && darkMode === true,
		'text-white': type === 'small' && darkMode === true || type === 'outline' && outlineVersion === 'dark',


	}">
	<span
		#contentWrapper
		class="flex items-center button-content"
		[ngClass]="{
				'justify-center': !contentPosition || contentPosition === 'center',
				'justify-between flex-row-reverse': contentPosition === 'stretched' || contentPosition === 'center-right',
				'justify-start': contentPosition === 'left',
				'gap-1' : type === 'small',
				'gap-2' : type !== 'small',

			}">
		<app-svg-icon
			[ngClass]="{ 'text-black-60': type === 'small', 'text-white-transparent': type === 'small' && darkMode === true }"
			*ngIf="iconId"
			[width]="iconWidth"
			[height]="iconHeight"
			[iconName]="iconId + '.svg'"
			[iconId]="iconId"></app-svg-icon>
		<span
			[ngClass]="{
		 'text-sm px-1' : type === 'small',
		}">
			<ng-content></ng-content>
		</span>
		<!-- empty element, used for e.g. identity button layout - text center, icon right -->
		<div *ngIf="contentPosition === 'center-right'"></div>
	</span>

	<div *ngIf="loading" class="flex items-center justify-center"><app-loader></app-loader></div>
</button>
