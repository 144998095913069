import { Injectable } from '@angular/core';
import {
	BankAccountDto,
	BankAccountRequest,
	BoardMemberDto,
	KycHttpService,
	OrganizationDto,
	OrganizationsHttpService,
	OrganizationUserAccessDto,
	SetPrimaryBankAccountRequest,
	SignatoryPersonDto,
	UpdateContactInfoRequest,
	UserAccessRequest,
} from 'app/api';
import { b64DecodeUnicode } from 'app/helpers/encoding.helper';
import { AuthService } from 'app/shared/auth';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { SentryService } from './sentry.service';
import { SessionCacheService } from './session-cache.service';

@Injectable({
	providedIn: 'root',
})
export class OrganizationService {
	constructor(
		private sessionCache: SessionCacheService,
		private organizationHttpService: OrganizationsHttpService,
		private kycHttpService: KycHttpService,
		private authService: AuthService,
		private localStorage: LocalStorageService,
		private sentryService: SentryService
	) {
		this.clearStoredOrganization();
	}

	private organizationId: string;
	private creditId: string;

	getOrganizations(partnerId?: string): Observable<OrganizationDto[]> {
		return this.organizationHttpService.getOrganizations(partnerId);
	}

	getOrganization(): Observable<OrganizationDto> {
		const storedOrganization = this.getStoredOrganization();

		if (storedOrganization) {
			return of(storedOrganization);
		}

		const organizationId = this.getOrganizationId();

		if (this.authService.isAuthenticated().isAuthenticated && organizationId) {
			return this.organizationHttpService
				.getOrganization(organizationId)
				.pipe(tap(organization => this.setStoredOrganization(organization)));
		}
	}

	checkOrganizationRequiresKyc(organizationId: string): Observable<boolean> {
		return this.kycHttpService.checkOrganizationRequiresKyc(organizationId);
	}

	getBoardMembers(): Observable<BoardMemberDto[]> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.getBoardMembers(organizationId);
	}

	getSignatories(): Observable<SignatoryPersonDto[]> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.getSignatories(organizationId);
	}

	getUsers(): Observable<OrganizationUserAccessDto[]> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.getOrganizationUsers(organizationId);
	}

	addUser(request: UserAccessRequest): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.createOrganizationUser(organizationId, request);
	}

	removeUser(userId: string): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.deleteOrganizationUser(organizationId, userId);
	}

	getCustomerSwedishBankAccounts(): Observable<BankAccountDto[]> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.getCustomerSwedishBankAccounts(organizationId);
	}

	addBankAccount(request: BankAccountRequest): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.addCustomerBankAccount(organizationId, request);
	}

	removeBankAccount(bankAccountId: string): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.deactivateCustomerBankAccount(organizationId, bankAccountId);
	}

	setPrimaryBankAccount(request: SetPrimaryBankAccountRequest): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.setCustomerPrimaryBankAccount(organizationId, request);
	}

	setAsSecondaryBankAccount(bankAccountId: string): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.setAsSecondaryBankAccount(bankAccountId, organizationId);
	}

	getIsCustomizedLending(organizationId: string): Observable<boolean> {
		return this.organizationHttpService.getIsCustomizedLending(organizationId);
	}

	updateContactInfo(userId: string, contactInfo: UpdateContactInfoRequest): Observable<{}> {
		const organizationId = this.getOrganizationId();
		return this.organizationHttpService.updateContactInfo(userId, organizationId, contactInfo);
	}

	clearOrganizationCache() {
		this.sessionCache.remove('organizationId');
		this.localStorage.remove('organizationId');
		this.sessionCache.remove('organization');
		this.sessionCache.remove('creditId');
		this.localStorage.remove('creditId');
		this.organizationId = undefined;
		this.creditId = undefined;
	}

	setOrganizationId(organizationId: string) {
		if (!organizationId) throw new Error('OrganizationId is undefined');
		this.organizationId = organizationId;
		this.sessionCache.set('organizationId', organizationId);
		this.localStorage.set('organizationId', organizationId);
		this.clearStoredOrganization();
	}

	getOrganizationId(): string {
		if (!this.organizationId) {
			if (this.sessionCache.get('organizationId')) {
				this.organizationId = this.sessionCache.get('organizationId');
			} else if (this.localStorage.get('organizationId')) {
				this.organizationId = this.localStorage.get('organizationId');
			}
		}
		return this.organizationId;
	}

	getStoredOrganization(): OrganizationDto {
		const cached = this.sessionCache.get('organization');
		return JSON.parse(cached);
	}

	setStoredOrganization(info: OrganizationDto): void {
		this.sessionCache.set('organization', JSON.stringify(info));
	}

	clearStoredOrganization(): void {
		this.sessionCache.remove('organization');
	}

	setCreditId(creditId: string) {
		this.creditId = creditId;
		this.sessionCache.set('creditId', creditId);
		this.localStorage.set('creditId', creditId);
	}

	getCreditId(): string {
		if (!this.creditId) {
			if (this.sessionCache.get('creditId')) {
				this.creditId = this.sessionCache.get('creditId');
			} else if (this.localStorage.get('creditId')) {
				this.creditId = this.localStorage.get('creditId');
			} else {
				this.sentryService.captureMessage('CreditId is not set', 'warning');
			}
		}
		return this.creditId;
	}

	setOrganizationIdFromToken(token: string) {
		const splits = token.split('.');
		const data = JSON.parse(b64DecodeUnicode(splits[1]));
		const organizationId = data.organization_id;
		if (!organizationId) this.sentryService.captureMessage('OrganizationId not found in token', 'warning');
		this.setOrganizationId(organizationId);
	}

	checkIsCompanyAB(orgNumber: string): boolean {
		return parseInt(orgNumber.substring(0, 1)) === 5 && parseInt(orgNumber.substring(2, 4)) >= 20;
	}
}
