import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContractType, LoansHttpService, SharedFileDto, SharedFileHeaderDto, SharedFilesHttpService } from 'app/api';
import { openFileLink } from 'app/helpers/file-link.helper';
import { OrganizationService, SentryService, ToastService, WhiteLabelClientSettingsService } from 'app/services';
import { Observable, map, shareReplay } from 'rxjs';
import { Agreement } from '../../../models/agreement';
import { LoanFilterLoan } from '../table-loan-filter/table-loan-filter.component';

export interface SharedFileExtended extends SharedFileDto {
	displayLoanId: string | null;
}

@Component({
	selector: 'app-contracts-ls',
	templateUrl: './contracts-ls.component.html',
	styleUrl: './contracts-ls.component.scss',
})
export class ContractsLsComponent {
	@Input() contracts: SharedFileDto[];
	@Input() agreements?: Agreement[];
	partnerKey: string;

	ContractType = ContractType;
	contractLoading: Record<string, boolean> = {};
	allLoans$: Observable<LoanFilterLoan[]>;
	lastAvailableLoans: LoanFilterLoan[] = [];

	mappedContracts: SharedFileExtended[] = [];

	constructor(
		private loansService: LoansHttpService,
		private translateService: TranslateService,
		private organizationService: OrganizationService,
		private sharedFilesService: SharedFilesHttpService,
		private toastService: ToastService,
		private sentryService: SentryService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	ngOnInit(): void {
		this.partnerKey = this.clientSettingsService.getSettings()?.key ?? null;

		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.allLoans$ = this.loansService.getAllLoans(organizationId, creditId).pipe(
			map(loans => {
				return loans.map(loan => ({
					id: loan.loanId,
					name: loan.displayId,
				}));
			}),
			shareReplay(1)
		);
		// Combine loans (async) and events (static) for processing
		this.allLoans$?.subscribe(loans => {
			if (this.contracts) {
				this.updateMappedContracts(loans, this.contracts);
			}
		});

		this.translateService.onLangChange.subscribe(_ => {
			this.updateMappedContracts(this.lastAvailableLoans, this.contracts);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.contracts) {
			this.updateMappedContracts(this.lastAvailableLoans, this.contracts);
		}
	}

	isContractLoading(id) {
		return this.contractLoading[id];
	}

	private updateMappedContracts(loans: LoanFilterLoan[] = [], contracts: SharedFileDto[] = []): void {
		this.lastAvailableLoans = loans; // Cache the latest loans
		this.mappedContracts = contracts.map(contract => {
			const loan = loans.find(loan => loan.id === contract.properties['LoanId']);
			return {
				...contract,
				displayLoanId: loan ? loan.name : null,
			};
		});
	}

	getContract(fileId) {
		if (this.contractLoading[fileId]) {
			return;
		}
		this.contractLoading[fileId] = true;
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		this.sharedFilesService.getSharedFileForOrganizationAndCredit(organizationId, creditId, fileId).subscribe(
			file => {
				this.contractLoading[fileId] = false;
				this.sentryService.addBreadcrumb('Contract URL fetched', 'files', {
					fileUrl: file.url,
				});
				this.sentryService.setTag('partner_key', this.partnerKey);
				this.sentryService.addComponentName('ContractsComponent');
				this.sentryService.captureMessage('File URL fetched');
				openFileLink(file.url, this.partnerKey);
			},
			error => {
				this.sentryService.addHttpResponseMessage(error.message);
				this.sentryService.captureMessage('Download contract error triggered', 'error');
				this.toastService.error('home.toast.download-contract.error');
				this.contractLoading[fileId] = false;
			}
		);
	}

	openAgreement(link: string) {
		window.open(link);
	}

	getContractTitle(contract: SharedFileHeaderDto): string {
		return (
			contract.properties?.ContractTitle ||
			contract.name ||
			this.translateService.instant('home.contracts.type.placeholder')
		);
	}
}
