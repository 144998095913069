import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/bootstrapping/appconfig';
import { b64DecodeUnicode } from 'app/helpers/encoding.helper';
import { BankIdUserInfo } from 'app/models/bankIdUserInfo';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class IdentityService {
	private clientId: string = this.appConfig.identityClientId;
	private redirectUrl = 'https://home.froda.se';
	private sessionId: string;

	constructor(private httpClient: HttpClient, private appConfig: AppConfig, private authService: AuthService) {}

	set codeVerifier(codeVerifier) {
		localStorage.setItem('code_verifier', codeVerifier);
	}

	get codeVerifier() {
		return localStorage.getItem('code_verifier');
	}

	set idToken(idToken: string) {
		localStorage.setItem('id_token', idToken);
	}

	get idToken(): string {
		return localStorage.getItem('id_token');
	}

	set accessToken(accessToken: string) {
		localStorage.setItem('access_token', accessToken);
	}

	get accessToken(): string {
		return localStorage.getItem('access_token');
	}

	set loginSessionId(sessionId: string) {
		this.sessionId = sessionId;
		sessionStorage.setItem('login_session_id', sessionId);
	}

	get loginSessionId(): string {
		return this.sessionId || sessionStorage.getItem('login_session_id');
	}

	initBankIDSession(scope = 'openid legacy_auth.token'): Observable<any> {
		this.codeVerifier = this.random(96);
		const codeVerifierBytes = this.stringToUint(this.codeVerifier);
		const hashedBytes$: Observable<ArrayBuffer> = from(window.crypto.subtle.digest('SHA-256', codeVerifierBytes));
		return hashedBytes$.pipe(
			switchMap(hashedBytes => {
				const codeChallenge = this.base64UrlEncode(this.uintToString(new Uint8Array(hashedBytes)));
				const nonce = this.random(64);
				const responseType = 'code';
				const state = uuidv4();
				return this.httpClient.get(
					`${this.appConfig.identityBaseUrl}/connect/authorize/bankid?client_id=${
						this.clientId
					}&code_challenge=${codeChallenge}&code_challenge_method=S256&nonce=${nonce}&redirect_uri=${encodeURIComponent(
						this.redirectUrl
					)}&response_type=${responseType}&scope=${encodeURIComponent(scope)}&state=${state}`
				);
			})
		);
	}

	pollBankIDAuthResponse(sessionId: string): Observable<any> {
		return this.httpClient.get(`${this.appConfig.identityBaseUrl}/connect/authorize/bankid/${sessionId}`);
	}

	pollBankIDSignResponse(sessionId: string): Observable<any> {
		return this.httpClient.get(
			`${this.appConfig.identityBaseUrl}/connect/authorize/bankid/sign/${sessionId}?callbackUrl=${this.appConfig.apiBaseUrl}/v1/contracts/${sessionId}/signing_callback`
		);
	}

	pollBankIDAuthResponseWithDevSSN(sessionId: string, devSSN: string): Observable<any> {
		return this.httpClient.get(`${this.appConfig.identityBaseUrl}/connect/authorize/bankid/${sessionId}?ssn=${devSSN}`);
	}

	pollBankIDSignResponseWithDevSSN(sessionId: string, devSSN: string): Observable<any> {
		return this.httpClient.get(
			`${this.appConfig.identityBaseUrl}/connect/authorize/bankid/sign/${sessionId}?ssn=${devSSN}&callbackUrl=${this.appConfig.apiBaseUrl}/v1/contracts/${sessionId}/signing_callback`
		);
	}

	getIdentityToken(
		code,
		codeVerifier = this.codeVerifier
	): Observable<{ access_token: string; expires_in: number; id_token: string; scope: string; token_type: string }> {
		const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;');

		const body = new HttpParams()
			.set('client_id', this.clientId)
			.set('grant_type', 'authorization_code')
			.set('redirect_uri', this.redirectUrl)
			.set('scope', '')
			.set('code', code)
			.set('code_verifier', codeVerifier);

		return this.httpClient.post<any>(`${this.appConfig.identityBaseUrl}/connect/token`, body, { headers });
	}

	getUserInfoFromToken(): BankIdUserInfo {
		if (!this.idToken) {
			return;
		}
		const splits = this.idToken.split('.');
		const data = JSON.parse(b64DecodeUnicode(splits[1]));
		return { given_name: data.given_name, surname: data.surname, ssn: data.ssn };
	}

	exchangeAccessToken(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.httpClient.post(
			`${this.appConfig.authBaseUrl}/auth/identity-server/exchange-token`,
			{ AccessToken: this.accessToken },
			{ headers }
		);
	}

	enrichAuthToken(creditId: string, lineOfCredit: string): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.httpClient.post(
			`${this.appConfig.authBaseUrl}/auth/enrich-organization-token`,
			{ creditId: creditId, lineOfCredit: lineOfCredit },
			{ headers }
		);
	}

	exchangeReferralPartnerAccessToken(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.httpClient.post(
			`${this.appConfig.authBaseUrl}/auth/identity-server/referral-partners/exchange-token`,
			{ AccessToken: this.accessToken },
			{ headers }
		);
	}

	createPartnerUserAuthCode(partnerToken: string): Observable<{ code: string }> {
		const headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', 'Bearer ' + partnerToken);
		return this.httpClient.post<any>(`${this.appConfig.identityBaseUrl}/partner/users/auth/code`, null, {
			headers: headers,
		});
	}

	createPartnerUserAuthSession(
		authSessionCode: string,
		successUrl: string,
		failureUrl: string
	): Observable<{ login_url: string }> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		const body = {
			code: authSessionCode,
			success_url: successUrl,
			failure_url: failureUrl,
		};
		return this.httpClient.post<any>(`${this.appConfig.identityBaseUrl}/partner/users/auth/sessions`, body, {
			headers,
		});
	}

	private random(length: number): string {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	private stringToUint(str: string): Uint8Array {
		const charList = str.split('');
		const uintArray = [];
		for (let i = 0; i < charList.length; i++) {
			uintArray.push(charList[i].charCodeAt(0));
		}
		return new Uint8Array(uintArray);
	}

	private uintToString(uintArray: Uint8Array): string {
		const encodedString = btoa(String.fromCharCode(...uintArray));
		return encodedString;
	}

	private base64UrlEncode(encoded: string): string {
		return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
	}
}
