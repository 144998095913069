<div class="flex flex-col">
	<div class="flex items-center justify-between w-full px-3 pt-6">
		<h1 class="mb-2" translate="events.title"></h1>
		<app-table-loan-filter *ngIf="showLoanFilter" [availableLoans]="allLoans$ | async" (selectedLoan)="handleLoanFilterChange($event)" />
	</div>
	<div *ngIf="events?.length > 0" class="flex flex-col flex-1 lg:px-3 min-h-128">
		<div class="hidden pb-4 md:flex lg:items-center lg:justify-between tabular-nums">
			<div class="flex-1 mb-4 overflow-y-auto">
				<ng-container *ngFor="let event of filteredEvents; let i = index">
					<app-event-item [event]="event"></app-event-item>
				</ng-container>
				<div class="flex justify-center w-full py-4 mb-2 lg:pb-0">
					<app-button *ngIf="!loading" [disabled]="!canLoadMore" (click)="canLoadMore && load.emit()" type="secondary" class="w-full text-center">
						{{ "transactions.more" | translate }}
					</app-button>
					<app-loader *ngIf="loading"></app-loader>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="events?.length === 0" class="flex-1 h-64 bg-white lg:rounded-lg lg:h-128 lg:shadow lg:border-none">
		<div class="flex flex-col items-center justify-center w-full h-full max-w-xs mx-auto text-center">
			<ng-container *ngIf="!loading">
				<p class="mb-2 font-semibold text-almost-black" translate="events.empty.title"></p>
				<p translate="events.empty.pre"></p>
			</ng-container>
			<app-loader *ngIf="loading"></app-loader>
		</div>
	</div>
</div>
